export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Tentang PUPR',
      //   to: '/tentangpupr',
      //   icon: 'cil-folder-open',
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Transactions',
        to: '/deposits',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Refunds',
        to: '/refunds',
        icon: 'cil-folder-open',
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Faucet',
      //   to: '/faucet',
      //   icon: 'cil-folder-open',
      // },
      {
        _name: 'CSidebarNavItem',
        name: 'Stake Packages',
        to: '/packages',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Stake Coins',
        to: '/coins',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Members',
        to: '/members',
        icon: 'cil-folder-open',
      },
      // {
      //   _name: 'CSidebarNavItem',
      //   name: 'Users',
      //   to: '/users',
      //   icon: 'cil-folder-open',
      // },
      {
        _name: 'CSidebarNavTitle',
        name: 'Setting',
        _children: ['Setting']
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Fee System',
        to: '/feesystem',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Member Activities',
        to: '/activities',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Tiket Support',
        to: '/ticket',
        icon: 'cil-folder-open',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'My Profile',
        to: '/myprofile',
        icon: 'cil-folder-open'
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Logout',
        to: '/logout',
        icon: 'cil-account-logout'
      },
    ]
  }
]