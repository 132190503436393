<template>
  <div class="c-app" style="margin-bottom: 50px;">
    <TheSidebar/>
    <CWrapper>
      <TheHeader/>
      <div class="c-body bg-dark">
        <main class="c-main">
          <CContainer fluid>
            <transition name="fade" mode="out-in">
              <router-view :key="$route.path"></router-view>
            </transition>
          </CContainer>
        </main>

      </div>
      <!-- <TheFooter/> -->
      
  <!-- <CHeader style="display:block;position: fixed;bottom:0%;left:0;height:50px;z-index: 1000;"  primary >
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Home
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/login">
          Logout
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
  </CHeader> 
style="height:50px;width:100%;position: fixed;bottom: 0;z-index: 10000;background-color: white;"
-->
      
    </CWrapper>
    <link rel="stylesheet" 
        href="https://use.fontawesome.com/releases/v5.2.0/css/all.css" 
        integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ" 
        crossorigin="anonymous">
  <RingBottomNavigation
  swiperColor='#8066C7'
  background-color='#000'
  iconColor='#8066C7' :options="options" v-model="selected" style='color:#fefefe' />
  </div>
</template>
<style scoped>
.fas {
    font-family: "Font Awesome 5 Free", "fa-solid-900";
    font-weight: 900;
}
</style>
<script>
import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
import TheFooter from './TheFooter'
import { RingBottomNavigation } from "bottom-navigation-vue";
import "@/assets/scss/bottom-navigation-vue/style.css";


export default {
  name: 'TheContainer',
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    RingBottomNavigation
  },
  data: () => ({
    selected: 1,
    options: [
      { 
        id: 1, 
        icon: "fas fa-home", 
        title: "Home", 
        path : { path: "/dashboard", }
      },
      { 
        id: 2, 
        icon: "fab fa-bitcoin", 
        title: "Tools", 
        path : { path: "/stakepanel", }
      },
      { 
        id: 3, 
        icon: "fas fa-exchange-alt", 
        title: "Earning", 
        path : { path: "/mystake", }
      },
      { 
        id: 4, 
        icon: "fas fa-wallet", 
        title: "Wallet", 
        path : { path: "/deposits", }
      },
    ],
  }),
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
